import axios, { AxiosError } from "axios";
import { Bell } from "lucide-react";
import millify from "millify";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ENUM_STATUS_CODE,
  notificationDateKeys,
  stringifyObjectToRequestQuery,
  stringifyRequestQuery,
} from "src/helpers";
import { useAuth } from "src/modules/Auth/Core";
import { data } from "src/modules/Portfolio/ProsumerTrading/Component/Table/data";
import {
  updateError,
  updateErrorCode,
  updateSuccessMess,
} from "src/redux/Slicer/errorHadlingSlicer";
import { getNotificationServicesCount } from "src/service/services";

const NotificationBell = () => {
  const [notificationDate, setNotificationDate] = useState("");
  const [count, setCount] = useState<any>();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { auth } = useAuth();
  const { pathname } = useLocation();
  useEffect(() => {
    const date = localStorage.getItem(notificationDateKeys);

    if (date) {
      setNotificationDate(date);
    } else return;
  }, [pathname]);

  const henadleNotificationCount = async (postData: any) => {
    setLoading(true);

    try {
      let apiResp: any = await getNotificationServicesCount(postData);
      dispatch(updateErrorCode(apiResp.code));
      if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {
        // navigate('login/otp')
        dispatch(updateSuccessMess(apiResp.message));
        setCount(apiResp?.count);
      } else {
        // dispatch(updateError(apiResp.message));
        dispatch(updateErrorCode(apiResp.code));
      }
      return apiResp.data;
    } catch (err) {
      let error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        // dispatch(updateError(error.response?.data.message));
        dispatch(updateErrorCode(error.response?.data.code));
      } else {
        dispatch(updateError(error.message));
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const a = {
      userId: auth?.user_id,
      startDate: notificationDate,
    };
    const queryString = stringifyObjectToRequestQuery(a);
    if (notificationDate) {
      henadleNotificationCount(queryString);
    } else return;
  }, [notificationDate, pathname]);

  const navigate = useNavigate();
  return (
    <div className="relative">
      <Bell
        className="w-8 h-8 text-text_primaryDarkBlue cursor-pointer"
        onClick={() => navigate("/profile/notifications")}
      />
      {count ? (
        <div className="w-6 h-6 bg-primaryDarkBlue rounded-full text-white text-[10px] flex justify-center items-center absolute -mt-10 ml-3">
          {millify(count)}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default NotificationBell;
