import { useFormik } from "formik";
import { Eye, EyeOff } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { Input } from "src/components/ui/input";
import {
  ChangePasswordValidation,
  ICreatePassword,
  IntialValuesCreatePassword,
} from "./modules";
import { Checkbox } from "src/components/ui/checkbox";
import { Label } from "src/components/ui/label";
import { Button } from "src/components/ui/button";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  fetchUser,
  onboardingReducerState,
} from "src/redux/Slicer/onboardingSlicer";
import { userRegistration } from "src/service/services";
import {
  updateError,
  updateErrorCode,
  updateSuccessMess,
} from "src/redux/Slicer/errorHadlingSlicer";
import { ENUM_STATUS_CODE } from "src/helpers";
import axios, { AxiosError } from "axios";
import Buttons from "src/components/ui/common/Button/Buttons";
import Modal from "src/components/ui/common/Dialog/Modal";
import ConfirmationModal from "./ConfirmationModal";
import Modal_md from "src/components/ui/common/Dialog/Modal_md";

type Props = {};

const CreatePass = (props: Props) => {
  const { fetchUserData }: any = useSelector((state: RootState) =>
    onboardingReducerState(state)
  );
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const basicDetails = useMemo(() => fetchUserData, [fetchUserData]);

  // useEffect(() => {
  //     dispatch(fetchUser())
  //     return () => { }
  // }, [])

  const formik = useFormik<ICreatePassword>({
    initialValues: IntialValuesCreatePassword,
    validationSchema: ChangePasswordValidation,
    onSubmit: (values: any) => {
      // console.log(values);
      let postData = { ...basicDetails, password: values?.password };
      console.log(postData);
      handleSubmit(postData);
    },
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmShowPassword(!confirmShowPassword);
  };

  const handleSubmit = async (postData: any) => {
    setLoading(true);
    try {
      let apiResp: any = await userRegistration(postData);
      dispatch(updateErrorCode(apiResp.code));
      if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {
        setOpenModal(true);
        // navigate('/')
        formik.resetForm();
        // dispatch(updateSuccessMess(apiResp.message))
      } else {
        dispatch(updateError(apiResp.message));
        dispatch(updateErrorCode(apiResp.code));
        // toast.error(apiResp.message)
      }
      return apiResp.data;
    } catch (err) {
      let error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        dispatch(updateError(error.response?.data.message));
        dispatch(updateErrorCode(error.response?.data.code));
      } else {
        dispatch(updateError(error.message));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="w-full ">
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full grid grid-cols-2 ml-0 mb-2 gap-x-6">
            <div>
              <label className={`text-grayLable text-xs`}>Password</label>
              <div className="relative mt-2 ">
                <div className="relative flex">
                  <Input
                    type={showPassword ? "text" : "password"}
                    className="block w-full border  border-input border-[#575757]  rounded-md py-1.5  pr-20 placeholder:text-gray-400 placeholder:text-xs "
                    placeholder="**********"
                    {...formik.getFieldProps("password")}
                  />
                  <div className=" h-[80%] absolute right-0 flex justify-end items-center px-2">
                    {showPassword ? (
                      <EyeOff
                        className="text-gray-400 font-bold mt-1 cursor-pointer"
                        size={20}
                        onClick={togglePasswordVisibility}
                      />
                    ) : (
                      <Eye
                        className="text-gray-400 font-bold mt-1 cursor-pointer"
                        size={20}
                        onClick={togglePasswordVisibility}
                      />
                    )}
                  </div>
                </div>
                {formik.touched.password && formik.errors.password && (
                  <div className="">
                    <div className="text-xs text-red-500">
                      {formik.errors.password}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div>
              <label className={`text-grayLable text-xs`}>
                Confirm Passowrd
              </label>
              <div className="relative mt-2 ">
                <div className="relative flex">
                  <Input
                    type={confirmShowPassword ? "text" : "password"}
                    className="block w-full border  border-input border-[#575757]  rounded-md py-1.5  pr-20 placeholder:text-gray-400 placeholder:text-xs "
                    placeholder="**********"
                    {...formik.getFieldProps("confirm_password")}
                  />
                  <div className=" h-[80%] absolute right-0 flex justify-end items-center px-2">
                    {confirmShowPassword ? (
                      <EyeOff
                        className="text-gray-400 font-bold mt-1 cursor-pointer"
                        size={20}
                        onClick={toggleConfirmPasswordVisibility}
                      />
                    ) : (
                      <Eye
                        className="text-gray-400 font-bold mt-1 cursor-pointer"
                        size={20}
                        onClick={toggleConfirmPasswordVisibility}
                      />
                    )}
                  </div>
                </div>
                {formik.touched.confirm_password &&
                  formik.errors.confirm_password && (
                    <div className="">
                      <div className="text-xs text-red-500">
                        {formik.errors.confirm_password}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>

          <div className="flex  items-center mt-6">
            <Checkbox
              onCheckedChange={(val) => formik.setFieldValue("checks", val)}
              {...formik.getFieldProps("checks")}
              className=" border border-input border-[#575757]"
            />
            <Label
              htmlFor="checks"
              className={`text-grayLable text-sm font-normal ml-2 `}
            >
              I accept the{" "}
              <Link
                to="https://cdn.websitepolicies.com/wp-content/uploads/2022/04/terms-and-conditions-template.pdf"
                className="text-text_primaryDarkBlue font-bold underline"
                target="_blanck"
              >
                Privacy Policy
              </Link>
            </Label>
          </div>
          <p className="text-sm text-red-600 ml-2 font-medium  mb-4">
            {formik.touched.checks && formik.errors.checks
              ? String(formik.errors.checks)
              : ""}
          </p>
          <div className="flex justify-between content-end">
            <Button
              className="text-sm  w-[200px] bg-primaryDarkBlue text-white "
              onClick={() => {
                navigate("/auth/onboarding/new-user");
              }}
            >
              Back
            </Button>
            {/* <Button type="submit" className="text-sm  w-[200px] bg-green_1 hover:bg-green_1 text-white "  >
                            Confirm & Register
                        </Button> */}
            <div className="w-[200px]">
              <Buttons
                btnName=" Confirm & Register"
                data-qa="loginbtn"
                type="submit"
                // disabled={!formik.isValid || formik.isSubmitting}
                id="login"
                loading={loading}
              />
            </div>
          </div>
        </form>
      </div>
      <Modal_md
        open={openModal}
        setOpen={setOpenModal}
        children={<ConfirmationModal setOpen={setOpenModal} />}
      />
    </div>
  );
};

export default CreatePass;
