import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ENUM_PURCHASE_TYPE,
  ENUM_STATUS_CODE,
  QueryState,
  stringifyRequestQuery,
} from "../../helpers";
import { RootState } from "../store";
import axios, { AxiosError } from "axios";

import {
  getConsumerList,
  getMarketDepth,

  getTradeProsumerListData,
  getTradeProsumerTradeConfirmation,
  getTradeProsumerTradeOrderRequest,
} from "../../service/services";

import dayjs from "dayjs";
import { updateError, updateErrorCode } from "./errorHadlingSlicer";


interface ListProps extends QueryState {
  message: string | undefined;
  error: boolean;
  dataChanged: boolean;
  isLoading: boolean;
  confirmationLoadingState: boolean;
  page: number;
  items_per_page: 10 | 30 | 50 | 100;
  prosumerTableData: any[];
  consumerTableData: any[];
  start_date: string;
  end_date: string;
  purchase_type: "buy" | "sell";
  orderType: "market" | "limit";
  rowSelectionArray: any[];
  orderIDs: any[];
  submissionTime: string | undefined;
  tradeConfirmationdata: any;
  modalProsumerTradeConfirmation: boolean;
  orderCategory: string | undefined;
  tradeOrderData: any[];
  tradeOrderSubmmitedSate: boolean;
  tableRowSelection: boolean;
  marketepthBuyData: any;
  marketDepthSellData: any;
  marketDepthDate: string | undefined;
  marketDepthModalState: boolean;
  order_unit: number;
  remainingUnit: number;
  checkValue: boolean;
  date:string
  // q: string;
}

const currentDate = dayjs(new Date()).format("YYYY-MM-DD");

const initialState: ListProps = {
  message: undefined,
  error: false,
  dataChanged: false,
  isLoading: false,
  confirmationLoadingState: false,
  page: 1,
  items_per_page: 10,
  prosumerTableData: [],
  consumerTableData: [],
  start_date: currentDate,
  end_date: currentDate,
  order: "desc",
  sort: "ratePerUnit",
  purchase_type: "buy",
  orderType: "market",
  rowSelectionArray: [],
  orderIDs: [],
  submissionTime: undefined,
  tradeConfirmationdata: {},
  modalProsumerTradeConfirmation: false,
  orderCategory: undefined,
  tradeOrderData: [],
  tradeOrderSubmmitedSate: false,
  tableRowSelection: false,
  marketepthBuyData: {},
  marketDepthSellData: {},
  marketDepthDate: undefined,
  marketDepthModalState: false,
  order_unit: 0,
  remainingUnit: 0,
  checkValue: false,
  date:''

};

const transformToQueryString = (data: any) => {
  const filterkeys: string[] = [
    // "start_date",
    // "end_date",
    "purchase_type",
    "sort",
    "order",
    "date",
    "user",
    // "items_per_page",
    // "page"
    // "q",
  ];
  const f_keys: string[] = [""];
  const q_keys: string[] = [""];

  let filters: any = {};
  let _f: any = {};
  let _q: any = {};

  data &&
    Object.entries(data).map(([key, value]) => {
      if (filterkeys.length > 0 && filterkeys.includes(key)) {
        filters[key] = value;
      } else if (f_keys.length > 0 && f_keys.includes(key)) {
        _f[key] = value;
      } else if (q_keys.length > 0 && q_keys.includes(key)) {
        _q[key] = value;
      }
    });



  return stringifyRequestQuery({
    filter: filters,
    f: _f,
    q: _q,
    items_per_page: data.items_per_page,
    page: data.page,
  });
};

export const prosumerList: any = createAsyncThunk(
  "list/prosumerList",
  async (data: any, thunkApi: any) => {

    
    try {
      const getFilterState = thunkApi.getState().list;

      let newFilterState = {
        ...getFilterState,
        ...data
      };
      delete newFilterState["purchase_type"];
      // delete newFilterState["page"];
      // delete newFilterState["items_per_page"];
     
      let filterState = {
        ...newFilterState,
        purchase_type:
          getFilterState?.purchase_type === ENUM_PURCHASE_TYPE?.BUY
            ? ENUM_PURCHASE_TYPE?.SELL
            : ENUM_PURCHASE_TYPE?.BUY,

      };
      const queryString = transformToQueryString(filterState);
     
      const response: any = await getTradeProsumerListData(queryString);

      if (response.code === ENUM_STATUS_CODE?.SUCCESS && response.data) {
        return response?.data; // Resolve the Promise with the successful response
      } else {
        const errorMessage = response.message;
        
        thunkApi.dispatch(updateError(errorMessage));
        thunkApi.dispatch(updateErrorCode(response?.code));
        return thunkApi.rejectWithValue(errorMessage);
      }
    } catch (_error) {
    
      const error = _error as Error | AxiosError;
     
      if (axios.isAxiosError(error)) {

        thunkApi.dispatch(updateError(error.response?.data.message));
        thunkApi.dispatch(updateErrorCode(error.response?.data?.code));
        return thunkApi.rejectWithValue(error.response?.data.message);
      } else {
        thunkApi.dispatch(updateError(error.message));
      }
    
      thunkApi.dispatch(updateError(error.message));
     
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const prosumerTradeConfirmationList: any = createAsyncThunk(
  "trade/prosumerTradeConfirmationList",
  async (data: any, thunkApi: any) => {
    const getFilterState = thunkApi.getState().list;
    try {
      const postData = {
        orderIDs: getFilterState?.rowSelectionArray,
        user: data?.user,
        orderDate: getFilterState?.start_date,
        submissionTime: getFilterState?.submissionTime,
        purchaseType: getFilterState?.purchase_type,
        orderCategory: getFilterState?.orderCategory,
        orderType: "limit",
      };
      const response: any = await getTradeProsumerTradeConfirmation(postData);

      // thunkApi.dispatch(updateErrorCode(response.code))

      if (response.code === ENUM_STATUS_CODE?.SUCCESS && response.data) {
        return response?.data; // Resolve the Promise with the successful response
      } else {
        const errorMessage = response.data?.message;
     
       
        thunkApi.dispatch(updateError(errorMessage));
        thunkApi.dispatch(updateErrorCode(response.data?.code));
        return thunkApi.rejectWithValue(errorMessage);
      }
    } catch (_error) {
      // Handle other errors, such as network errors
      const error = _error as Error | AxiosError;
      if (axios.isAxiosError(error)) {
       
        thunkApi.dispatch(updateErrorCode(error.response?.data?.code));
        thunkApi.dispatch(updateError(error.response?.data.message));
        return thunkApi.rejectWithValue(error.response?.data.message);
      } else {
        thunkApi.dispatch(updateError(error.message));
      }
      thunkApi.dispatch(setError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const prosumerTradeOrderRequest: any = createAsyncThunk(
  "trade/prosumerTradeOrderRequest",
  async (data: any, thunkApi: any) => {
    const getFilterState = thunkApi.getState().list;
   
    try {
      const postData = {
        ...data,
      };
      const response: any = await getTradeProsumerTradeOrderRequest(postData);
      // thunkApi.dispatch(updateErrorCode(response.code))

      if (response.code === ENUM_STATUS_CODE?.SUCCESS && response.data) {
        return response?.data; // Resolve the Promise with the successful response
      } else {
        const errorMessage = response.message;
        
        thunkApi.dispatch(updateError(errorMessage));
        thunkApi.dispatch(updateErrorCode(response.data?.code));
        return thunkApi.rejectWithValue(errorMessage);
      }
    } catch (_error) {
      // Handle other errors, such as network errors
      const error = _error as Error | AxiosError;
     
      if (axios.isAxiosError(error)) {
        thunkApi.dispatch(updateErrorCode(error.response?.data.code));
        thunkApi.dispatch(updateError(error.response?.data.message));
        return thunkApi.rejectWithValue(error.response?.data.message);
      } else {
        thunkApi.dispatch(updateError(error.message));
      }
      thunkApi.dispatch(setError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const consumerList: any = createAsyncThunk(
  "list/consumerList",
  async (data: any, thunkApi: any) => {
    try {
      const getFilterState = thunkApi.getState().list;
     

      let newFilterState = {
        ...getFilterState,
        sort: "remaining_units",
        order: "desc",
      };

      const queryString = transformToQueryString(newFilterState);
      
      const response: any = await getConsumerList(queryString);
   

      if (response.code === ENUM_STATUS_CODE?.SUCCESS && response.data) {
        return response?.data; // Resolve the Promise with the successful response
      } else {
        const errorMessage = response.data?.message;
        thunkApi.dispatch(updateErrorCode(response.data?.code));
        thunkApi.dispatch(updateError(errorMessage));
        return thunkApi.rejectWithValue(errorMessage);
      }
    } catch (_error) {
      // Handle other errors, such as network errors
      const error = _error as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        thunkApi.dispatch(setError(error.response?.data.message));

        thunkApi.dispatch(updateError(error.response?.data.message));
        thunkApi.dispatch(updateErrorCode(error.response?.data.code));
        return thunkApi.rejectWithValue(error.response?.data.message);
      } else {
        thunkApi.dispatch(updateError(error.message));
      }
      thunkApi.dispatch(setError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const prosumerMarketDepthBuyDataFetch: any = createAsyncThunk(
  "trade/marketDepthBuyDataFetch",
  async (_: any, thunkApi: any) => {
    try {
      const getFilterState = thunkApi.getState().list;
     

      let newFilterState = { ...getFilterState };

      delete newFilterState["page"];
      delete newFilterState["purchase_type"];
      delete newFilterState["start_date"];
      delete newFilterState["orderType"];
      delete newFilterState["end_date"];
      delete newFilterState["order"];
      delete newFilterState["sort"];

      const queryString = transformToQueryString(newFilterState);

      const postData = {
        date: getFilterState?.marketDepthDate,
        // purchaseType: "buy",
        orderType: "market",
      };

      const response: any = await getMarketDepth(postData, queryString);
     
      if (response.code === ENUM_STATUS_CODE?.SUCCESS && response.data) {
        return response?.data; // Resolve the Promise with the successful response
      } else {
        const errorMessage = response.data?.message;
        thunkApi.dispatch(updateErrorCode(response.data?.code));
        thunkApi.dispatch(updateError(errorMessage));
        return thunkApi.rejectWithValue(errorMessage);
      }
    } catch (_error) {
      const error = _error as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        thunkApi.dispatch(setError(error.response?.data.message));

        thunkApi.dispatch(updateError(error.response?.data.message));
        thunkApi.dispatch(updateErrorCode(error.response?.data.code));
        return thunkApi.rejectWithValue(error.response?.data.message);
      } else {
        thunkApi.dispatch(updateError(error.message));
      }
      thunkApi.dispatch(setError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

const prosumerConListSlicer = createSlice({
  name: "prosumerConListSlicer",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<any>) => {
      state.page = action.payload;
    },
    setItemsPerPage: (state, action: PayloadAction<any>) => {
      state.items_per_page = action.payload;
    },
    setPaggination: (state, action: PayloadAction<any>) => {
      state.items_per_page = action.payload.b;
      state.page = action.payload.pageIndex;
    },
    resetChangedState(state) {
      state.dataChanged = false;
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = true;
      state.message = action.payload.message;
    },
    resetError: (state) => {
      state.error = false;
      state.message = undefined;
    },
    setResetState(state) {
      state.items_per_page = initialState.items_per_page;
      state.page = initialState.page;
      // state.dropdownOfferState = initialState.dropdownOfferState;
      // state.dropdownCategoryState = initialState.dropdownCategoryState
    },
    updateCalenderDate: (state, action: PayloadAction<any>) => {
      state.start_date = action.payload;
      state.end_date = action.payload;
      state.tableRowSelection = false;
    },
    updateOrderType: (state, action: PayloadAction<any>) => {
      state.purchase_type = action.payload;
      state.order = action.payload === ENUM_PURCHASE_TYPE?.BUY ? "asc" : "desc";
      state.tableRowSelection = false;
    },
    updateTypeOrder: (state, action: PayloadAction<any>) => {
      state.orderType = action.payload;
    },
    updateRowSelectionArray: (state, action: PayloadAction<any>) => {
      state.rowSelectionArray = action.payload;
    },
    updateSubmissionDate: (state, action: PayloadAction<any>) => {
      state.submissionTime = action?.payload;
    },
    modalProsumerTradeConfirmationState(state, action: PayloadAction<any>) {
      state.modalProsumerTradeConfirmation = action.payload;
    },
    modalTradeSubmmitedState(state, action: PayloadAction<any>) {
      state.tradeOrderSubmmitedSate = action.payload;
    },
    updateOrderCategory(state, action: PayloadAction<any>) {
      state.orderCategory = action?.payload;
    },
    updateMarketDepthModalState(state, action: PayloadAction<any>) {
      state.marketDepthModalState = action.payload;
    },
    updateMarketDepthDate(state, action: PayloadAction<any>) {
      state.marketDepthDate = action.payload;
    },
    // calculateRemainingUnit(state, action: PayloadAction<any>) {
    //   state.remainingUnit = action.payload;
    // },
    updateCheckboxvalueforDisableBtn(state, action: PayloadAction<any>) {
      state.checkValue = action.payload;
    },
    setProsumerStartDateEndDate(state, action: PayloadAction<any>) {
      state.start_date = action.payload?.start_date;
      state.end_date = action.payload?.end_date;
    },

    updateQkey(state, action: PayloadAction<any>) {
      state.sort = action.payload.sort;
      state.order=action.payload.order
    },
    updateDate(state, action: PayloadAction<any>) {
      // console.log(action.payload)
      state.date = action.payload
     
    },
    resetState: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(prosumerList.fulfilled, (state, action) => {
      // console.log(action?.payload?.payload?.pagination?.items_per_page)
      state.prosumerTableData = action?.payload?.data;
      state.order_unit = action?.payload?.user?.order_unit;
      state.isLoading = false;
      state.error = false;
      state.message = undefined;
      state.tableRowSelection = true;
      state.remainingUnit=action?.payload?.user?.remainingUnit
      state.items_per_page = action?.payload?.payload?.pagination?.items_per_page;
      state.page = action?.payload?.payload?.pagination?.page;
      state.total_records = action?.payload?.payload?.pagination?.total_records;
      state.total = action?.payload?.payload?.pagination?.total;
      state.last_page=action?.payload?.payload?.pagination?.last_page
      // state.items_per_page = 50;
      // state.page = 1;
      // state.total_records = '100';
      // state.total = 1000;
    });
    builder.addCase(prosumerList.pending, (state) => {
      state.isLoading = true;
      state.error = false;
      state.message = "";
      state.prosumerTableData = [];
    });
    builder.addCase(prosumerList.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = true;
      state.message = payload || "Error Occured";
    });

    // consumer list Extar reducer

    builder.addCase(consumerList.fulfilled, (state, { payload }) => {
      state.consumerTableData = payload?.data;
      state.isLoading = false;
      state.error = false;
      state.message = undefined;
      state.items_per_page = payload?.payload?.pagination?.items_per_page;
      state.page = payload?.payload?.pagination?.page;
      state.total_records = payload?.payload?.pagination?.total_records;
      state.total = payload?.payload?.pagination?.total;
    });
    builder.addCase(consumerList.pending, (state) => {
      state.isLoading = true;
      state.error = false;
      state.message = "";
      state.consumerTableData = [];
    });
    builder.addCase(consumerList.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = true;
      state.message = payload || "Error Occured";
    });

    // trade confirmation
    builder.addCase(
      prosumerTradeConfirmationList.fulfilled,
      (state, { payload }) => {
        state.tradeConfirmationdata = payload;
        state.isLoading = false;
        state.error = false;
        state.message = undefined;
        state.modalProsumerTradeConfirmation = true;
        // state.items_per_page = payload?.payload?.pagination?.items_per_page;
        // state.page = payload?.payload?.pagination?.page;
        // state.total_records = payload?.payload?.pagination?.total_records;
        // state.total = payload?.payload?.pagination?.total;
      }
    );
    builder.addCase(prosumerTradeConfirmationList.pending, (state) => {
      state.isLoading = true;
      state.error = false;
      state.message = "";
      state.tradeConfirmationdata = [];
      state.modalProsumerTradeConfirmation = false;
    });
    builder.addCase(
      prosumerTradeConfirmationList.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = true;
        state.message = payload || "Error Occured";
        state.modalProsumerTradeConfirmation = false;
      }
    );

    builder.addCase(
      prosumerTradeOrderRequest.fulfilled,
      (state, { payload }) => {
        state.tradeOrderData = payload;
        state.confirmationLoadingState = false;
        state.error = false;
        state.message = undefined;
        state.tradeOrderSubmmitedSate = true;
        state.tableRowSelection = true;
        state.modalProsumerTradeConfirmation = false;
        // state.items_per_page = payload?.payload?.pagination?.items_per_page;
        // state.page = payload?.payload?.pagination?.page;
        // state.total_records = payload?.payload?.pagination?.total_records;
        // state.total = payload?.payload?.pagination?.total;
      }
    );
    builder.addCase(prosumerTradeOrderRequest.pending, (state) => {
      state.confirmationLoadingState = true;
      state.error = false;
      state.message = "";
      state.tradeOrderData = [];
      state.tradeOrderSubmmitedSate = false;
      state.tableRowSelection = false;
    });
    builder.addCase(
      prosumerTradeOrderRequest.rejected,
      (state, { payload }) => {
        state.confirmationLoadingState = false;
        state.error = true;
        state.message = payload || "Error Occured";
        state.tradeOrderSubmmitedSate = false;
        state.tableRowSelection = false;
      }
    );

    // market depth buy
    builder.addCase(
      prosumerMarketDepthBuyDataFetch.fulfilled,
      (state, action) => {
        state.marketepthBuyData = action?.payload;
        state.isLoading = false;
        state.error = false;
        state.message = undefined;
      }
    );
    builder.addCase(prosumerMarketDepthBuyDataFetch.pending, (state) => {
      state.isLoading = true;
      state.error = false;
      state.message = "";
      state.marketepthBuyData = [];
    });
    builder.addCase(
      prosumerMarketDepthBuyDataFetch.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = true;
        state.message = payload || "Error Occured";
      }
    );
  },
});

export const {
  setError,
  modalTradeSubmmitedState,
  updateCheckboxvalueforDisableBtn,
  // calculateRemainingUnit,
  resetError,
  resetChangedState,
  modalProsumerTradeConfirmationState,
  updateTypeOrder,
  updateSubmissionDate,
  setItemsPerPage,
  setPage,
  setPaggination,
  setResetState,
  updateCalenderDate,
  updateOrderType,
  updateRowSelectionArray,
  updateOrderCategory,
  updateMarketDepthModalState,
  updateMarketDepthDate,
  setProsumerStartDateEndDate,
  updateQkey,
  updateDate,resetState
} = prosumerConListSlicer.actions;
export const listReducerState = (state: RootState) => state.list;
export default prosumerConListSlicer.reducer;
