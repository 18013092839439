import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {
  ENUM_ORDER_CATEGORY,
  ENUM_PURCHASE_TYPE,
  ENUM_STATUS_CODE,
  QueryState,
  stringifyRequestQuery,
} from "src/helpers";
import { RootState } from "../store";
import axios, { AxiosError } from "axios";
import { updateError, updateErrorCode } from "./errorHadlingSlicer";
import {
  getDirectTradeOrderList,
  getMarketDepth,
  getMeterDetailsTradeApi,
  getPlantDetailsTradeApi,
  getTradeDirectTradeOrderRequest,
} from "src/service/services";
import { ProsumerColumns } from "src/modules/Trade/Component/Direct/Component/TableComponent/Component/directColum";

interface DirectTradingProps extends QueryState {
  message: string | undefined;
  error: boolean;
  dataChanged: boolean;
  isLoading: boolean;
  tardeConfirmationLoading: boolean;
  page: number;
  items_per_page: 10 | 30 | 50 | 100;
  purchaseType: "buy" | "sell";
  orderType: "market" | "limit";
  start_date: string;
  end_date: string;
  modalDirectTradeConfirmation: boolean;
  tradeDirectOrderSubmmitedState: boolean;
  directTadingListData: any[];
  marketepthBuyData: any;
  marketDepthSellData: any;
  marketDepthDate: string;
  marketDepthOrderCategory: string;
  marketDepthModalState: boolean;
  directTradeConfirmationModalState: boolean;
  directRowSelectionArray: any[];
  directOrderCategory: string | undefined;
  directTradeList: any[];
  endTs: string;
  startTs: string;
  tradeConfirmationData: {};
  tableRowSelectionState: boolean;
  tradeOrderSubmmitedSate: boolean;
  dashboardNextSevenDayDate: string | undefined;
  orderCategory:string
    // | ENUM_ORDER_CATEGORY.INTRADAY
    // | ENUM_ORDER_CATEGORY.DAY_AHEAD
    // | ENUM_ORDER_CATEGORY.TERM_AHED;
}

const currentDate = dayjs(new Date()).format("YYYY-MM-DD");

const initialState: DirectTradingProps = {
  message: undefined,
  error: false,
  dataChanged: false,
  isLoading: false,
  tardeConfirmationLoading: false,
  page: 1,
  items_per_page: 10,
  purchaseType: ENUM_PURCHASE_TYPE?.BUY,
  orderType: "limit",
  start_date: currentDate,
  end_date: "",
  startTs: "",
  endTs: "",
  modalDirectTradeConfirmation: false,
  tradeDirectOrderSubmmitedState: false,
  directTadingListData: [],
  marketDepthSellData: {},
  marketepthBuyData: {},
  marketDepthDate: "",
  marketDepthOrderCategory: "",
  marketDepthModalState: false,
  directRowSelectionArray: [],

  directOrderCategory: undefined,
  directTradeList: [],
  directTradeConfirmationModalState: false,
  tradeConfirmationData: {},
  tableRowSelectionState: false,
  tradeOrderSubmmitedSate: false,
  dashboardNextSevenDayDate: undefined,
  orderCategory: "",
};

const transformToQueryString = (data: any) => {
  const filterkeys: string[] = [
    "start_date",
    "end_date",
    "purchaseType",
    "user",
    "orderType",
    " date",
    "orderCategory",
  ];
  const f_keys: string[] = [""];
  const q_keys: string[] = [""];

  let filters: any = {};
  let _f: any = {};
  let _q: any = {};

  data &&
    Object.entries(data).map(([key, value]) => {
      if (filterkeys.length > 0 && filterkeys.includes(key)) {
        filters[key] = value;
      } else if (f_keys.length > 0 && f_keys.includes(key)) {
        _f[key] = value;
      } else if (q_keys.length > 0 && q_keys.includes(key)) {
        _q[key] = value;
      }
    });

  return stringifyRequestQuery({
    filter: filters,
    f: _f,
    q: _q,
    items_per_page: data.items_per_page,
    page: data.page,
  });
};

export const directTradingApiList: any = createAsyncThunk(
  "direct/directTradingApiList",
  async (data: any, thunkApi: any) => {
    try {
      const getFilterState = thunkApi.getState().direct;
      const filterState = { ...getFilterState, ...data };

      let newFilterState = {
        ...filterState,
      };

      delete newFilterState["items_per_page"];
      delete newFilterState["page"];
      delete newFilterState["orderType"];
      delete newFilterState["start_date"];
      delete newFilterState["end_date"];


      const queryString = transformToQueryString(newFilterState);

      const response: any = await getDirectTradeOrderList(queryString);

      // thunkApi.dispatch(updateErrorCode(response.code))

      if (response.code === 200 && response.data) {
        return response?.data; // Resolve the Promise with the successful response
      } else {
        const errorMessage = response.message;

        thunkApi.dispatch(updateError(errorMessage));
        thunkApi.dispatch(updateErrorCode(response?.code));

        return thunkApi.rejectWithValue(errorMessage);
      }
    } catch (_error) {
      const error = _error as Error | AxiosError;

      if (axios.isAxiosError(error)) {
        thunkApi.dispatch(setError(error.response?.data.message));
        // thunkApi.dispatch(updateError(error.response?.data.message))
        thunkApi.dispatch(updateErrorCode(error.response?.data.code));
        // toast.error(error.response?.data.message)
        return thunkApi.rejectWithValue(error.response?.data.message);
      } else {
        // toast.error(error.message)
        thunkApi.dispatch(updateError(error.message));
      }
      thunkApi.dispatch(setError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const directMarketDepthBuyDataFetch: any = createAsyncThunk(
  "dashboard/marketDepthBuyDataFetch",
  async (_: any, thunkApi: any) => {
    try {
      const getFilterState = thunkApi.getState().direct;
      // console.log(getFilterState);

      let newFilterState = { ...getFilterState };

      delete newFilterState["page"];
      delete newFilterState["purchaseType"];
      delete newFilterState["start_date"];
      delete newFilterState["orderType"];
      delete newFilterState["end_date"];

      const queryString = transformToQueryString(newFilterState);

      const postData = {
        date: getFilterState?.marketDepthDate,
        // purchaseType: "buy",
        orderType: getFilterState?.orderType,
      };

      const response: any = await getMarketDepth(postData, queryString);
      // thunkApi.dispatch(updateErrorCode(response.code))
      if (response.code === 200 && response.data) {
        return response?.data; // Resolve the Promise with the successful response
      } else {
        const errorMessage = response.data?.message;
        // console.log(errorMessage);
        thunkApi.dispatch(updateErrorCode(response.data?.code));
        // thunkApi.dispatch(updateError(errorMessage))

        return thunkApi.rejectWithValue(errorMessage);
      }
    } catch (_error) {
      const error = _error as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        thunkApi.dispatch(setError(error.response?.data.message));
        thunkApi.dispatch(updateErrorCode(error.response?.data.code));
        // thunkApi.dispatch(updateError(error.response?.data.message))
        return thunkApi.rejectWithValue(error.response?.data.message);
      } else {
        // thunkApi.dispatch(updateError(error.message))
        // console.log(error)
      }
      thunkApi.dispatch(setError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const directTradeConfirmationList: any = createAsyncThunk(
  "trade/directTradeConfirmationList",
  async (data: any, thunkApi: any) => {
    try {
      const response: any = await getTradeDirectTradeOrderRequest(
        data?.postData
      );
      // thunkApi.dispatch(updateErrorCode(response.code))
      if (response.code === ENUM_STATUS_CODE?.SUCCESS && response.data) {
        return response; // Resolve the Promise with the successful response
      } else {
        const errorMessage = response.message;
        // console.log(errorMessage);
        thunkApi.dispatch(updateError(errorMessage));
        thunkApi.dispatch(updateErrorCode(response.code));
        // console.log(errorMessage)

        return thunkApi.rejectWithValue(errorMessage);
      }
    } catch (_error) {
      // Handle other errors, such as network errors
      const error = _error as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        // console.log("catch errorr with axios");
        thunkApi.dispatch(setError(error.response?.data.message));
        thunkApi.dispatch(updateErrorCode(error.response?.data.code));
        // thunkApi.dispatch(updateError(error.response?.data.message))
        return thunkApi.rejectWithValue(error.response?.data.message);
      } else {
        // thunkApi.dispatch(updateError(error.message))
       
      }
      thunkApi.dispatch(setError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

const directTradingSlicer = createSlice({
  name: "directTradingSlicer",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<any>) => {
      state.page = action.payload;
    },
    setItemsPerPage: (state, action: PayloadAction<any>) => {
      state.items_per_page = action.payload;
    },
    setPaggination: (state, action: PayloadAction<any>) => {
      state.items_per_page = action.payload.pageSize;
      state.page = action.payload.pageIndex;
    },
    resetChangedState(state) {
      state.dataChanged = false;
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = true;
      state.message = action.payload.message;
    },
    resetError: (state) => {
      state.error = false;
      state.message = undefined;
    },
    setResetState(state) {
      state.items_per_page = initialState.items_per_page;
      state.page = initialState.page;
    },

    updatePurchaseType: (state, action: PayloadAction<any>) => {

      state.purchaseType = action.payload?action.payload:ENUM_PURCHASE_TYPE?.BUY;
      state.tableRowSelectionState = true;
    },
    updateOrderTypeDirect: (state, action: PayloadAction<any>) => {
      state.orderType = action.payload;
      state.tableRowSelectionState = true;
    },
    modalDirectTradeConfirmationState(state, action: PayloadAction<any>) {
      state.modalDirectTradeConfirmation = action.payload;
    },
    modalDirectTradeSubmmitedState(state, action: PayloadAction<any>) {
      state.tradeDirectOrderSubmmitedState = action.payload;
    },
    updateEndDate(
      state,
      action: PayloadAction<{
        endDate: string;
        endTS: string;
        startTs: string;
      }>
    ) {
      // state.end_date = action.payload?.endDate
      state.endTs = action.payload?.endTS;
      state.startTs = action.payload?.startTs;
    },
    updateMarketDepthDate(state, action: PayloadAction<any>) {
      state.marketDepthDate = action.payload;
    },
    updateMarketDepthCategory(state, action: PayloadAction<any>) {
      state.marketDepthOrderCategory = action.payload;
    },
    updateMarketDepthModalState(state, action: PayloadAction<any>) {
      state.marketDepthModalState = action.payload;
    },
    updateTradeConfirmationModalState(state, action: PayloadAction<any>) {
      state.directTradeConfirmationModalState = action.payload;
    },
    updateDirectRowselection(state, action: PayloadAction<any>) {
      state.directRowSelectionArray = action.payload;
    },
    updateDirectOrdercategory(state, action: PayloadAction<any>) {
      state.directOrderCategory = action.payload;
    },

    updateDirectTradeList(state, action: PayloadAction<any>) {
      state.directTradeList = action.payload;
    },
    updateDirectTradeUnits(
      state,
      action: PayloadAction<{
        date: string | undefined;
        rate: number;
        last_traded_price: number;
        order_category: string;
        order_unit: number;
        trade_unit: number;
        remaining_Unit: number;
        minRate: number;
        maxRate: number;
      }>
    ) {
      const {
        date,
        trade_unit,
        last_traded_price,
        order_category,
        order_unit,
        rate,
        remaining_Unit,
        minRate,
        maxRate,
      } = action.payload;
      if (state.directTradeList && state.directTradeList) {
        let items = state.directTradeList;
        let itemIndex = items.findIndex((d: ProsumerColumns) => d.date == date);
        
        items[itemIndex] = {
          date: date,
          trade_unit: trade_unit,
          rate: rate,
          last_traded_price: last_traded_price,
          order_category: order_category,
          order_unit: order_unit,
          remaining_Unit: remaining_Unit,
          minRate: minRate,
          maxRate: maxRate,
        };
        state.directTradeList = items;
        state.dataChanged = true;
      }
    },

    // updateDirectTradeRates(
    //   state,
    //   action: PayloadAction<{
    //     date: string | undefined;
    //     rate: number;
    //     last_traded_price: number;
    //     order_category: string;
    //     order_unit: number;
    //     trade_unit: number;
    //     remaining_Unit: number;
    //     minRate: number;
    //     maxRate: number;
    //   }>
    // ) {
    //   const {
    //     date,
    //     trade_unit,
    //     last_traded_price,
    //     order_category,
    //     order_unit,
    //     rate,
    //     remaining_Unit,
    //     minRate,
    //     maxRate,
    //   } = action.payload;
    //   if (state.directTradeList && state.directTradeList) {
    //     let items = state.directTradeList;
    //     let itemIndex = items.findIndex((d: ProsumerColumns) => d.date == date);
    //     items[itemIndex] = {
    //       date: date,
    //       trade_unit: trade_unit,
    //       rate: rate,
    //       last_traded_price: last_traded_price,
    //       order_category: order_category,
    //       order_unit: order_unit,
    //       remaining_Unit: remaining_Unit,
    //       minRate: minRate,
    //       maxRate: maxRate,
    //     };
    //     state.directTradeList = items;
    //     state.dataChanged = true;
    //   }
    // },

    modalTradeSubmmitedState(state, action: PayloadAction<any>) {
      state.tradeOrderSubmmitedSate = action.payload;
    },
    updateTableRowSelectionState(state, action: PayloadAction<any>) {
      state.tableRowSelectionState = action?.payload;
    },
    dashboardNextSevenDayDateState(state, action: PayloadAction<any>) {
      state.dashboardNextSevenDayDate = action.payload;
    },
    setStartDateEndDate(state, action: PayloadAction<any>) {
      state.start_date = action.payload?.start_date;
      state.end_date = action.payload?.end_date;
    },
    updateorderCategoryDirect(state, action: PayloadAction<any>) {
      state.orderCategory = action.payload;
    },
    // reset: (state) => {
    //   // console.log(initialState)
    // state=initialState; // reset state to initial state
    // },
    reset: () => initialState
  },
  extraReducers: (builder) => {
    // direct trade api list
    builder.addCase(directTradingApiList.fulfilled, (state, action) => {
      state.directTadingListData = action?.payload;

      state.isLoading = false;
      state.error = false;
      state.message = undefined;
      state.tableRowSelectionState = false;
    });
    builder.addCase(directTradingApiList.pending, (state) => {
      state.isLoading = true;
      state.error = false;
      state.message = "";
      state.directTadingListData = [];
      // state.tableRowSelectionState=false
    });
    builder.addCase(directTradingApiList.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = true;
      state.message = payload || "Error Occured";
      // state.tableRowSelectionState=false
    });

    // market depth buy
    builder.addCase(
      directMarketDepthBuyDataFetch.fulfilled,
      (state, action) => {
        state.marketepthBuyData = action?.payload;
        state.isLoading = false;
        state.error = false;
        state.message = undefined;
      }
    );
    builder.addCase(directMarketDepthBuyDataFetch.pending, (state) => {
      state.isLoading = true;
      state.error = false;
      state.message = "";
      state.marketepthBuyData = [];
    });
    builder.addCase(
      directMarketDepthBuyDataFetch.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = true;
        state.message = payload || "Error Occured";
      }
    );

    // trade confirmation list
    builder.addCase(directTradeConfirmationList.fulfilled, (state, action) => {
      state.tradeConfirmationData = action?.payload;

      state.tardeConfirmationLoading = false;
      state.error = false;
      state.message = undefined;
      state.tableRowSelectionState = true;
      state.tradeOrderSubmmitedSate = true;
      state.directTradeConfirmationModalState = false;
      state.dashboardNextSevenDayDate = undefined;
    });
    builder.addCase(directTradeConfirmationList.pending, (state) => {
      state.tardeConfirmationLoading = true;
      state.error = false;
      state.message = "";
      state.tradeConfirmationData = [];
      state.tableRowSelectionState = false;
      state.tradeOrderSubmmitedSate = false;
    });
    builder.addCase(
      directTradeConfirmationList.rejected,
      (state, { payload }) => {
        state.tardeConfirmationLoading = false;
        state.error = true;
        state.message = payload || "Error Occured";
        state.tableRowSelectionState = false;
        state.tradeOrderSubmmitedSate = false;
      }
    );
  },
});

export const {
  modalDirectTradeConfirmationState,
  modalDirectTradeSubmmitedState,
  resetError,
  resetChangedState,
  setError,
  setItemsPerPage,
  setPage,
  setPaggination,
  setResetState,
  updateOrderTypeDirect,
  updatePurchaseType,
  updateEndDate,
  updateMarketDepthDate,
  updateMarketDepthModalState,
  updateDirectRowselection,
  updateDirectOrdercategory,
  updateDirectTradeList,
  updateDirectTradeUnits,
  updateMarketDepthCategory,
  updateTradeConfirmationModalState,
  modalTradeSubmmitedState,
  updateorderCategoryDirect,
  updateTableRowSelectionState,
  dashboardNextSevenDayDateState,
  setStartDateEndDate,
  reset
} = directTradingSlicer.actions;
export const directReducerState = (state: RootState) => state.direct;
export default directTradingSlicer.reducer;
