import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios, { AxiosError } from "axios";
import { updateError, updateErrorCode } from "./errorHadlingSlicer";

import { stringifyRequestQuery } from "../../helpers";
import {
  checkUserEligibilty,
  fetchUserDetails,
  getOnBoardingStatus,
} from "../../service/services";

interface OnboardingProps {
  error: boolean;
  message: string | undefined;
  isLoading: boolean;
  dataChanged: boolean;
  phone: string | undefined;
  onBoardingData: any;
  eligibleData: any;
  fetchUserData: any;
  // urlObj: any,
  otpData: any;
  verifyTokenData: any;
}

const intialValue: OnboardingProps = {
  error: false,
  message: undefined,
  isLoading: false,
  dataChanged: false,
  onBoardingData: undefined,
  eligibleData: {},
  fetchUserData: {},
  phone: "",
  // urlObj: {},
  otpData: {},
  verifyTokenData: {},
};

const transformToQueryString = (data: any) => {
  const filterkeys: string[] = ["phone", "email", "consumerNo", "token"];
  const f_keys: string[] = [""];
  const q_keys: string[] = [""];

  let filters: any = {};
  let _f: any = {};
  let _q: any = {};

  data &&
    Object.entries(data).map(([key, value]) => {
      if (filterkeys.length > 0 && filterkeys.includes(key)) {
        filters[key] = value;
      } else if (f_keys.length > 0 && f_keys.includes(key)) {
        _f[key] = value;
      } else if (q_keys.length > 0 && q_keys.includes(key)) {
        _q[key] = value;
      }
    });

  return stringifyRequestQuery({
    filter: filters,
    f: _f,
    q: _q,
    items_per_page: data.items_per_page,
    page: data.page,
  });
};

export const onboardingStatus: any = createAsyncThunk(
  "onboard/onboardingStatus",
  async (data: any, thunkApi: any) => {
    try {
      let newFilterState = { ...data };

      const queryString = transformToQueryString(newFilterState);

      if (queryString !== undefined) {
        const response: any = await getOnBoardingStatus(queryString);
        // thunkApi.dispatch(updateErrorCode(response.code))

        if (response.code === 200 && response.data) {
          return response?.data; // Resolve the Promise with the successful response
        } else {
          const errorMessage = response.data?.message;

          thunkApi.dispatch(updateError(errorMessage));
          thunkApi.dispatch(updateErrorCode(response.data.code));

          return thunkApi.rejectWithValue(errorMessage);
        }
      }
    } catch (_error) {
      const error = _error as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        // thunkApi.dispatch(updateError(error.response?.data.message));
        thunkApi.dispatch(updateErrorCode(error.response?.data.code));

        return thunkApi.rejectWithValue(error.response?.data.message);
      } else {
        // toast.error(error.message)
        // thunkApi.dispatch(updateError(error.message));
      }
      thunkApi.dispatch(setError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const checkEligibilityUser: any = createAsyncThunk(
  "onboard/checkEligibilityUser",
  async (data: any, thunkApi: any) => {
    try {
      let newFilterState = { ...data };

      const queryString = transformToQueryString(newFilterState);
      // thunkApi.dispatch(resetOnboardingState());
      if (queryString !== undefined) {
        const response: any = await checkUserEligibilty(queryString);
        // thunkApi.dispatch(updateErrorCode(response.code))

        if (response.code === 200 && response.data) {
          if (response?.data?.consumerNo) {
            thunkApi.dispatch(
              onboardingStatus({ consumerNo: response.data.consumerNo })
            );
            localStorage.setItem("x-apiKey", response.data?.apiKey);
          }
          return response?.data; // Resolve the Promise with the successful response
        } else {
          const errorMessage = response.data?.message;

          thunkApi.dispatch(updateError(errorMessage));
          thunkApi.dispatch(updateErrorCode(response.data.code));
          // console.log(response);
          return thunkApi.rejectWithValue(errorMessage);
        }
      } else return;
    } catch (_error) {
      const error = _error as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        // thunkApi.dispatch(updateError(error.response?.data.message));
        // thunkApi.dispatch(updateErrorCode(error.response?.data.code));
        // console.log("catch");
        return thunkApi.rejectWithValue(error.response?.data.message);
      } else {
        // toast.error(error.message)
        thunkApi.dispatch(updateError('Unauthorized! Invalid token.'));
       
      }
      thunkApi.dispatch(setError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const fetchUser: any = createAsyncThunk(
  "onboard/fetchUser",
  async (data: any, thunkApi: any) => {
    try {
      let newFilterState = { ...data };
      const queryString = transformToQueryString(newFilterState);

      const response: any = await fetchUserDetails(queryString);

      // thunkApi.dispatch(updateErrorCode(response.code))

      if (response.code === 200 && response.data) {
        return response?.data; // Resolve the Promise with the successful response
      } else {
        const errorMessage = response.message;

        thunkApi.dispatch(updateError(errorMessage));
        thunkApi.dispatch(updateErrorCode(response.code));

        return thunkApi.rejectWithValue(errorMessage);
      }
    } catch (_error) {
      const error = _error as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        thunkApi.dispatch(updateError("no consumer found"));
        // thunkApi.dispatch(updateError(error.response?.data.message))
        // thunkApi.dispatch(updateErrorCode(error.response?.data.code))

        return thunkApi.rejectWithValue(error.response?.data.message);
      } else {
        // toast.error(error.message)
        // thunkApi.dispatch(updateError(error.message))
      }
      thunkApi.dispatch(setError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const onboardingSlicer = createSlice({
  name: "onboarding/onboardingSlicer",
  initialState: intialValue,
  reducers: {
    resetChangedState(state) {
      state.dataChanged = false;
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = true;
      state.message = action.payload.message;
    },
    resetError: (state) => {
      state.error = false;
      state.message = undefined;
    },
    updateOnboardingPhoneNumber: (state, action: PayloadAction<any>) => {
      state.phone = action.payload;
    },
    updateOnboardingData: (state, action: PayloadAction<any>) => {
      state.onBoardingData = action.payload;
    },
    updateEligibleData: (state, action: PayloadAction<any>) => {
      state.eligibleData = action.payload;
    },
    // updateUrlOBJ: (state, action: PayloadAction<any>) => {
    //     state.urlObj = action.payload
    // },
    updateOtpOBJ: (state, action: PayloadAction<any>) => {
      state.otpData = action.payload;
    },
    resetOnboardingState: (state) => {
      return {
        ...state,
        ...intialValue,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(onboardingStatus.fulfilled, (state, action) => {
      state.onBoardingData = action?.payload && action?.payload;
      state.isLoading = false;
      state.error = false;
      state.message = undefined;
    });
    builder.addCase(onboardingStatus.pending, (state) => {
      state.isLoading = true;
      state.error = false;
      state.message = "";
      state.onBoardingData = {};
    });
    builder.addCase(onboardingStatus.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = true;
      state.message = payload || "Error Occured";
    });

    // Check eligiblity data

    builder.addCase(checkEligibilityUser.fulfilled, (state, action) => {
      console.log(action?.payload);
      // state.eligibleData = action?.payload && action?.payload
      state.verifyTokenData = action?.payload;
      state.isLoading = false;
      state.error = false;
      state.message = undefined;
    });
    builder.addCase(checkEligibilityUser.pending, (state) => {
      state.isLoading = true;
      state.error = false;
      state.message = "";
      // state.eligibleData = {}
      state.verifyTokenData = {};
    });
    builder.addCase(checkEligibilityUser.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = true;
      state.message = payload || "Error Occured";
    });

    //  fetch user

    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.fetchUserData = action?.payload && action?.payload;
      state.isLoading = false;
      state.error = false;
      state.message = undefined;
    });
    builder.addCase(fetchUser.pending, (state) => {
      state.isLoading = true;
      state.error = false;
      state.message = "";
      state.fetchUserData = {};
    });
    builder.addCase(fetchUser.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = true;
      state.message = payload || "Error Occured";
    });
  },
});

export const {
  setError,
  resetError,
  resetChangedState,
  updateOnboardingPhoneNumber,
  updateOtpOBJ,
  resetOnboardingState,
} = onboardingSlicer.actions;
export const onboardingReducerState = (state: RootState) => state.onboarding;
export default onboardingSlicer.reducer;
