import { Loader2 } from 'lucide-react'
import React from 'react'
// import LoadingIcon from '../../../../assets/images/Loader-new-4_Final_Grey.gif'
import LoadingIcon from '../../../../assets/images/Loader_2.gif'
interface LoaderProps{
    loading:boolean
}
const Loader = () => {
  return (
    <div className='w-full flex justify-center items-center'>
            {/* <div className='w-32 h-12 bg-gray-800 flex justify-center items-center rounded-lg'>
    <Loader2 className="mr-2 h-4 w-4 animate-spin text-white" /> <span className='text-white'>Loading...</span> */}


    {/* </div> */}
    <div className='w-full flex justify-center items-center flex-col p-2'>
    <div className='w-20'><img src={LoadingIcon} alt='loading' className=''/></div>
    <div className='text-sm font-semibold text-gray-700  text-center' data-testid="loading-state">Loading<span className='animate-spin text-xl font-bold'>...</span></div>
    </div>
</div>
  )
}

export default Loader
