import axios from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { getAuth, useAuth } from "../modules/Auth/Core";
import { API_URL, AUTH_URL } from "./apis";
import { getX_ApiKey } from "src/modules/Auth/Core/XApiKeyHelper";
// import { RootState } from "src/redux/store";
// import { onboardingReducerState } from "src/redux/Slicer/onboardingSlicer";
// import { useSelector } from "react-redux";
// import { useOnlineStatus } from "src/modules/Auth/Core/OnlineStatusContext";
// import { useOnlineStatus } from "src/modules/Auth/Core/OnlineStatusContext";

export const axiosWithToken = axios.create({
  baseURL: API_URL, // Replace with your API base URL
});

export const axiosWithTokenAuth = axios.create({
  baseURL: AUTH_URL,
});

export const axiosWithOnboardingAuth = axios.create({
  baseURL: AUTH_URL,
});

// You can also set up request interceptors or other configurations
axiosWithToken.interceptors.request.use(
  (config: any) => {
    const token = getAuth();
    // Do something before each request, like adding authentication headers
    config.headers["Authorization"] = `Bearer ${token?.api_token}`;
    // config.headers[`x-api-key `]='507dcede-459b-4ad1-bc67-9defe8e1c509'
    return config;
  },
  (error: any) => {
    // Handle request error
    return Promise.reject(error);
  }
);

axiosWithTokenAuth.interceptors.request.use(
  (config: any) => {
    const auth = getAuth();
    // const status=useOnlineStatus()

    config.headers["Authorization"] = `Bearer ${auth?.temp_token}`;

    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

// onboarding

axiosWithOnboardingAuth.interceptors.request.use(
  (config: any) => {
    const x_apiKey = getX_ApiKey();
    console.log(x_apiKey)
    // const status=useOnlineStatus()
    // const { verifyTokenData } = useSelector(
    //   (state: RootState) => onboardingReducerState(state)
    // );
    // config.headers["x-api-key"] = `507dcede-459b-4ad1-bc67-9defe8e1c509`;
    config.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key":`${ x_apiKey}`,
    };

    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);
