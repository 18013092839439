import { BarChart3, CalendarCheck, FileClock, FileLock2, FileSpreadsheet, HeartHandshake, History, LandPlot, LayoutDashboard, ListChecks, LogOut, MenuIcon, Milestone, Settings, Sigma, User, Users, Variable } from 'lucide-react'


export interface IMenuItem {
    name: string,
    iconName: any,
    path: string,
    options?: Array<{
        name: string,
        iconName: any,
        path: string,
    }>
}

export const Menu: IMenuItem[] = [
    {
        name: "Dashboard",
        iconName: (className: string) => <LayoutDashboard size={16} className={className} />,
        path: "/dashboard"
    },
    {
        name: "Portfolio",
        iconName: (className: string) => <LayoutDashboard size={16} className={className} />,
        path: "/portfolio"
    },
    {
        name: "Trading",
        iconName: (className: string) => <LayoutDashboard size={16} className={className} />,
        path: "/trade"
    },

    {
        name: "Order History",
        iconName: (className: string) => <HeartHandshake size={16} className={className} />,
        path: "/order-history"
    },

    {
        name: "Invoice",
        iconName: (className: string) => <FileSpreadsheet size={16} className={className} />,
        path: "/invoice"
    },

    {
        name: "Profile",
        iconName: (className: string) => <FileSpreadsheet size={16} className={className} />,
        path: "/profile"
    },

    {
        name: "Contact Us",
        iconName: (className: string) => <FileSpreadsheet size={16} className={className} />,
        path: "/contact-us"
    },
]

export const otherMenuForHeading: IMenuItem[] = [
    {
      name: "Profile/Notification",
      iconName: (className: string) => (
        <LayoutDashboard size={16} className={className} />
      ),
      path: "/profile/notifications",
    },
    {
      name: "Profile/Frequently Asked Questions",
      iconName: (className: string) => (
        <LayoutDashboard size={16} className={className} />
      ),
      path: "/profile/faqs",
    },
    {
        name: "Profile/Change Password",
        iconName: (className: string) => (
          <LayoutDashboard size={16} className={className} />
        ),
        path: "/profile/change-pasword",
      },
  ];