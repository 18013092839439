import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { Toaster } from 'src/components/ui/toaster'
import { useToast } from 'src/components/ui/use-toast'
import { errorReducerState, updateResetError } from 'src/redux/Slicer/errorHadlingSlicer'
import { RootState } from 'src/redux/store'
import { Toaster, toast } from 'sonner'
import { useAuth } from '../Auth/Core'
import { ENUM_STATUS_CODE } from 'src/helpers'
import { useLocation } from 'react-router-dom'
import { useOnlineStatus } from '../Auth/Core/OnlineStatusContext'
const ErrorBoundryMain = () => {
    const { errorMessage, isError, isWarning, warningMessage, errorCode, successMessage } = useSelector((state: RootState) => errorReducerState(state))
    // const { toast } = useToast();
    const dispatch = useDispatch()
    const { setCurrentUser, logout } = useAuth()
    const { pathname } = useLocation()
    const {isOnline}=useOnlineStatus()
  
    useEffect(() => {
        if (isError) {
            if(errorMessage){
                // console.log(errorMessage)
                toast.error(errorMessage)
            }
            
        }
        else if (isWarning) {
            
            toast.warning(warningMessage)
           
        }
        else if(!isOnline){
            toast.error('You lost your internet connection')
        }
        dispatch(updateResetError())

    }, [errorMessage, isError, isWarning, warningMessage,isOnline,errorCode])

    useEffect(() => {
        if (errorCode === ENUM_STATUS_CODE?.AUTHENDICATE) {

            logout()
        } else if (errorCode === ENUM_STATUS_CODE?.SUCCESS && successMessage !== '' &&successMessage !== undefined ) {
            toast.success(successMessage)
        }
        dispatch(updateResetError())
    }, [errorCode, successMessage])
    return (
        <div>
{ pathname.includes('auth')? <Toaster position="top-center" richColors />: <Toaster position="top-right" richColors />}
            {/* <Toaster position="top-right" richColors /> */}
        </div>
    )
}

export default ErrorBoundryMain
