import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import App from '../App';
import AppRoutes from './AppRoutes';
import AuthRoutes from './AuthRoutes';
import { useAuth } from 'src/modules/Auth/Core';
import StepwiseRegistrationMain from 'src/modules/StepwiseRegistration/StepwiseRegistrationMain';




const { PUBLIC_URL } = process.env;

const Navigations = () => {

    const { currentUser,auth} = useAuth()
    // console.log();


    return (
        <BrowserRouter basename={PUBLIC_URL}>


            <Routes >
                <Route element={<App />}>

                    {
                    currentUser?.isAuthenticated 
                    // true
                    ? (

                        <>
                            <Route path="/*" element={<AppRoutes />} />
                            
                                <Route index element={<Navigate to="/dashboard" />} />
                            
                      
                        </>
                    ) : (
                        <>
                            <Route path="auth/*" element={<AuthRoutes />} />
                            <Route path='*' element={<Navigate to="auth/" />} />
                        </>
                    )}

                    {/* {currentUser?.isAuthenticated ? (
                        !auth?.isLoadDetailsAvl && !auth?.isMeterDetailsAvl && !auth?.isPlantDetailsAvl ? (<>
                            <Route path="/*" element={<AppRoutes />} />
                            <Route path="/step" element={<StepwiseRegistrationMain />} />
                            <Route index element={<Navigate to="/step" />} />
                        </>) : (
                            <>
                                <Route path="/*" element={<AppRoutes />} />
                                <Route index element={<Navigate to="/dashboard" />} />
                            </>
                        )

                    ) : (
                        <>
                            <Route path="auth/*" element={<AuthRoutes />} />
                            <Route path='*' element={<Navigate to="auth/" />} />
                        </>
                    )} */}
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default Navigations;
