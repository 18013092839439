import { Button } from "src/components/ui/button";
import { Card, CardHeader } from "../../../components/ui/card";
import React, { useEffect, useMemo, useState } from "react";
import {
  AlignJustify,
  Bell,
  BellIcon,
  History,
  LogOut,
  MapPin,
  Minus,
  RotateCcw,
  Search,
  User,
} from "lucide-react";
import Logo from "src/assets/images/Electreecity-LogoPNG.png";
import { getAuth, useAuth } from "../../Auth/Core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu, otherMenuForHeading } from "../SideBar/modules";
import dayjs from "dayjs";
import { SidebarProps } from "../SideBar/Sidebarlayout";
import { convertToCamelCase } from "../../../helpers";
import SwitchUser from "../../../assets/images/SwitchUser.svg"


const Headers = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const { logout, auth } = useAuth();

  const { pathname } = useLocation();
  const menuOptions = [...Menu,...otherMenuForHeading];
console.log(menuOptions)
  const heading = useMemo(() => {
    for (const cur of menuOptions) {
      if (cur.path && pathname===cur.path) {
        return cur.name;
      } else if (cur.options && cur.options.length > 0) {
        for (const ind of cur.options) {
          if (ind.path && pathname.includes(ind.path)) {
            return `${cur.name}-${ind.name}`;
          }
        }
      } 
      // else if (pathname.includes("profile")) {
      //   return "My Profile";
      // }
    }
    return ""; // Default case if no match found
  }, [menuOptions, pathname]);

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 6000); // Update every minute
    return () => clearInterval(interval);
  }, []);
  const formattedDate = dayjs(currentTime).format("DD-MM-YYYY");
  const formattedTime = dayjs(currentTime).format("HH:mm ");
const navigate=useNavigate()
  return (
    // <div className='w-full cursor-default'>
    //   <Card className='w-full h-16 flex items-center rounded-none '>
    //     <div className='w-60 flex justify-center'>
    //       <img src={Logo} alt='logo' className=' h-12  transition delay-150 hover:-translate-y-1 hover:scale-110 duration-300 ease-in-out flex items-center cursor-default' />
    //     </div>
    //   </Card>
    // </div>

    <header className="w-full sticky top-0 z-999 flex bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-md drop-shadow-md lg:rounded-md">
      <nav className="w-full flex items-center justify-between h-12 px-1  md:py-4 md:px-6">
        <div className="text-sm text-textLightBlack font-normal flex  items-center space-x-4">
          <div className="md:hidden sm:flex">
            <div
              className=" p-1 bg-gray-200 rounded-md "
              onClick={() => setSidebarOpen(!sidebarOpen)}
              data-testid="alignJustify"
            >
              <AlignJustify />
            </div>
          </div>
          <div> {heading}</div>
        </div>
        <div className="flex space-x-2 items-center">
          {/* <div className='border-2 rounded-full p-1 border-[#caec9a]'>
            <span className='flex items-center space-x-2'><Search className='text-lightGreen' size={14} />
              <input type="text" placeholder='Search' className='bg-transparent ' />
            </span>

          </div> */}
          {/* <span><MapPin color='#FFFFFF' /></span>
          <span><RotateCcw color='#FFFFFF' /></span>
          <span><Bell color='#FFFFFF' /></span> */}
          <div className="w-full py-4 px-8">
            <div className="w-full flex justify-between items-center">
              {/* <div className='w-full text-base font-bold text-textLightBlack'>{heading}</div> */}
              <div className="w-full text-xs font-Roboto  font-normal text-textLightBlack  whitespace-nowrap flex space-x-2 justify-end items-center">
                {/* <span className="space-x-2 text-sm font-normal font-Roboto text-textLightBlack ">
                  <span data-testid="formattedDate">{formattedDate}</span>
                  <span id="formattedTime">{formattedTime}</span>
                </span> */}
                {/* <span>|</span> */}
                {/* <span><RotateCcw size={16} /></span> */}
                {/* <span className='text-sm font-normal font-Roboto text-textLightBlack'>Pull to Refresh</span> */}
              </div>
            </div>
          </div>
          {/* <span className='bg-iconBg rounded-full p-1'><History color='#FFFFFF' size={20} /></span>
          <span className='bg-iconBg rounded-full p-1'><BellIcon color='#FFFFFF' size={20} /></span> */}
          {/* <span className='bg-primaryDarkBlue rounded-full p-1 cursor-pointer' title='Logout' onClick={logout}><LogOut color='#FFFFFF' size={20} /></span> */}
          <span className=" rounded-full p-1">
            <Link to="/user-selection" title="Switch User">
              {/* <User color="#FFFFFF" size={20} /> */}
              <img src={SwitchUser} alt="switchUser" className="w-20 h-20"/>
            </Link>
          </span>
          <span>
          <Bell className="w-5 h-5 text-text_primaryDarkBlue cursor-pointer"
          onClick={()=>navigate('/profile/notifications')}
          />
          </span>
          
          <span
            className="flex text-textLightBlack text-sm font-normal whitespace-nowrap"
            title="Username"
          >
            {" "}
            {auth?.userCode || "-"}
          </span>
        </div>
      </nav>
    </header>
  );
};

export default Headers;
