import React from 'react'
import BasicDetails from './Components/BasicDetails'
import ConsumerListAccordian from './Components/ConsumerListAccordian'
import { Button } from 'src/components/ui/button'
import { useNavigate } from 'react-router-dom'


type Props = {}

const NewUserMain = (props: Props) => {
    const navigate = useNavigate()
    return (
        <div className='p-6 font-Montserrat'>
            <div className='mb-10'>
                <div className='text-gray-500 font-bold'>User Onboarding</div>
                <div className='text-gray-600 text-xs'>Peer To Peer  Energy Trading Application</div>
            </div>

            <div className='p-2'>
                <BasicDetails />
            </div>
            <div className='mb-4'>
                <div className='mb-2'>
                    <div className='font-semibold text-gray-400 text-sm '>Consumer Lists</div>
                    <div className='h-1  inset-x-0 bg-green-400 w-1/4'></div>
                </div>
                <ConsumerListAccordian />

            </div>

            <div className='flex flex-row-reverse'>
                <Button className="text-sm  w-[200px] bg-primaryDarkBlue hover:bg-primaryDarkBlue text-white " onClick={() => navigate("/auth/onboarding/create-password")} >
                    Next
                </Button>
            </div>


        </div>
    )
}

export default NewUserMain